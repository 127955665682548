<template>
    <div class="container" id="content">
        <div class="title">
            <div class="title-top">
                <div class="el-title-item">
                    <el-form ref="form" label-width="80px">
                        <el-form-item label="">
                            <el-button type="success" size="small" @click="addOption">新增</el-button>
                        </el-form-item>
                        <!-- <el-form-item label="" style="margin-left: 2vw">
                            <el-input v-model="searchName" placeholder="请输入名称"></el-input>
                        </el-form-item> -->
                    </el-form>
                </div>
                <!-- <el-button style="margin-left: 1vw;" type="success" size="small" @click="search">搜索</el-button> -->
            </div>
        </div>
        <el-table
                v-loading="loading"
                ref="multipleTable"
                :data="tableData"
                tooltip-effect="dark"
                style="width: 100%; margin-top: 1vh;">
            <el-table-column prop="dyxm" label="姓名" show-overflow-tooltip align="center"></el-table-column>
            <el-table-column prop="position" label="职位" show-overflow-tooltip align="center"></el-table-column>
            <el-table-column prop="dzzId" label="所属党组织" show-overflow-tooltip align="center">
                <template slot-scope="scope">
                    <span>{{getNames(scope.row.dzzId)}}</span>
                </template>
            </el-table-column>
            <el-table-column prop="tel" label="联系方式" show-overflow-tooltip align="center"></el-table-column>
            <el-table-column prop="url" label="图片" show-overflow-tooltip align="center">
                <template slot-scope="scope">
                    <img :src="scope.row.url" width="40" height="40" />
                </template>
            </el-table-column>
            <el-table-column label="操作" align="center" width="150">
                <template slot-scope="scope">
                    <!-- <el-button
                        type="primary"
                        size="small"
                        icon="el-icon-document"
                        @click="details(scope.row)"
                    ></el-button> -->
                    <el-button
                            type="primary"
                            size="small"
                            icon="el-icon-edit"
                            @click="edits(scope.row)"
                    ></el-button>
                    <el-button
                            type="primary"
                            size="small"
                            icon="el-icon-delete"
                            @click="deletes(scope.row.id)"
                    ></el-button>
                </template>
            </el-table-column>
        </el-table>
        <div class="block pagingBox">
            <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="page"
                    :page-sizes="[10, 20, 30, 40]"
                    :page-size="size"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="total"
            >
            </el-pagination>
        </div>
        <!--    编辑-->
        <el-dialog title="" :visible.sync="messageBox" width="31vw" class="addAlameBox">
            <div slot="title" class="header-title" style="position: relative">
                <span class="title-name"></span>
                <span class="title-age">{{ formTitle }}</span>
                <span style=" position: absolute; top: 50%; transform: translateY(-50%); right: 0px; font-size: 2.22vh;"></span>
            </div>
            <div class="addAlaForm">
                <el-form ref="addForm" :rules="rules" :model="addForm" label-width="10vw">
                    <el-form-item label="姓名" prop="dyxm">
                        <el-select
                            v-model="addForm.dyxm"
                            placeholder="请选择党员姓名"
                            @change="nameChange"
                            >
                            <el-option
                                v-for="item in organizList"
                                :key="item.id"
                                :label="item.dyxm"
                                :value="item.id + '+' + item.dyxm"
                            ></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item class="isUpload" label="图片" prop="url">
                        <el-upload class="avatar-uploader" action="/imgUpdata" :on-success="handleAvatarSuccess"
                            :before-upload="beforeAvatarUpload" :show-file-list="false">
                            <img v-if="addForm.url" :src="addForm.url" class="businessImg  avatar" />
                            <i v-else class="el-icon-plus avatar-uploader-icon uploadImg"></i>
                        </el-upload>
                    </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer" v-show="showFlag">
                    <el-button type="info" @click="messageBox = false">取消</el-button>
                    <el-button type="primary" @click="saveForm('addForm')">确定</el-button>
                </div>
            </div>
        </el-dialog>
    </div>
</template>
<script>
    export default {
        name: 'sensorManagement',
        data() {
            return {
                tableData: [],
                page: 1,
                size: 10,
                total: 0,
                loading: false,
                ids: 0,
                searchName: '',
                addForm: {},
                rules: {
                    dyxm: [
                        {required: true, message: "姓名不可为空", trigger: ["blur", "change"]},
                    ],
                    url: [
                        {required: true, message: "图片不可为空", trigger: ["blur", "change"]},
                    ],
                },
                formTitle: "",
                messageBox: false,
                showFlag: false,
                value: true,
                organizList: [],
                partyList: [],
                typeList: [
                    { id: 0, name: '否' },
                    { id: 1, name: '是' },
                ],
            };
        },
        mounted() {
            this.getOrganizationList();
            this.getOrganizationList2();
            this.getList();
            // //console.log(this.$store.state.user);
        },
        methods: {
            nameChange(val){
                this.addForm.dyid = val.split("+")[0];
                this.addForm.dyxm = val.split("+")[1];
            },
            //请求列表数据
            getList() {
                this.$get("/dygl/listYxdy", {
                    page: this.page,
                    size: this.size,

                }).then((res) => {
                    if (res.data.state == "success") {
                        this.tableData = res.data.data;
                        this.total = res.data.count;
                    }
                });
            },
            // 党员信息列表
            getOrganizationList2() {
                this.$get("/dygl/page", {
                    page: this.page,
                    size: this.size,
                }).then((res) => {
                    if (res.data.state == "success") {
                        this.organizList = res.data.datas;
                        //console.log(this.organizList)
                    }
                });
            },
            // 党组织列表
            getOrganizationList() {
                this.$get("/dzzgl/page", {
                    page: this.page,
                    size: this.size,
                }).then((res) => {
                    if (res.data.state == "success") {
                        this.partyList = res.data.datas;
                    }
                });
            },
            getNames(val){
                for(let a= 0; a < this.partyList.length; a++){
                    if(val == this.partyList[a].id){
                        return this.partyList[a].zzmc;
                    }
                }
            },
            // 编辑
            edits(row) {
                this.showFlag = true;
                this.ids = row.id;
                this.formTitle = "编辑";
                this.messageBox = true;
                this.addForm = JSON.parse(JSON.stringify(row));
            },
            // 删除
            deletes(id) {
                // 弹出提示是否删除
                this.$confirm("此操作将永久删除该记录, 是否继续?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                })
                    .then(() => {
                        this.$delete(`/dygl/deleteYxdy/${id}`).then((res) => {
                            if (res.data.state == "success") {
                                this.$message.success("删除成功");
                                this.getList();
                            } else {
                                this.$message.error("删除失败");
                            }
                        });
                    })
                    .catch((e) => e);
            },
            // 确认提交
            saveForm(formName) {
                this.addForm.id = this.ids;
                let url = '/dygl/saveYxdy';
                if(this.formTitle == '新增'){
                    delete this.addForm.id;
                }
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.$postJSON(url, this.addForm
                        ).then((res) => {
                            if (res.data.state == 'success') {
                                if(this.formTitle == '新增'){
                                    this.$message.success('添加成功')
                                }else{
                                    this.$message.success('修改成功')
                                }
                                this.messageBox = false
                                this.getList();
                            } else {
                                this.$message.error(res.data.msg)
                                this.messageBox = false
                            }
                        })
                    } else {
                        // //console.log('error submit!!');
                        return false;
                    }
                });
            },
            // 新增
            addOption() {
                this.formTitle = "新增";
                this.messageBox = true;
                this.showFlag = true;
                this.$nextTick(() => {
                    this.addForm = {};
                    this.$refs.addForm.resetFields();
                });
            },
            // 上传图片
            beforeAvatarUpload(val) {
                const fileLimitSize = 1;// 几兆限制
                const isJPG = val.type === "image/jpeg";
                const isJPGs = val.type === "image/png";
                const fileLimit = val.size / 1024 / 1024 < fileLimitSize;
                if (!isJPG && !isJPGs) {
                    this.$message.info("上传图片格式只能为 JPG 或 PNG 格式！");
                    return false
                }
                if (!fileLimit) {
                    this.$message.info(`上传图片大小不能超过${fileLimitSize}MB!`);
                    return false
                }
                return true;
            },
            handleAvatarSuccess(res) {
                this.addForm.url =
                    "https://public.half-half.cn/" + res.data.fileName;
                this.addForm = JSON.parse(JSON.stringify(this.addForm))
            },
            search() {
                //console.log(11111)
                this.page = 1
                this.getList();
            },
            handleSizeChange(val) {
                this.size = val;
                this.getList();
            },
            handleCurrentChange(val) {
                this.page = val;
                this.getList();
            },
        },
    };
</script>

<style scoped lang="less">
    @import "../../../style/backTable.less";
</style>
<style scoped>
    #content /deep/ .el-table th {
        background-color: rgba(240, 242, 245, 1) !important;
    }

    #content /deep/ .el-table {
        max-height: 78vh;
        overflow-y: scroll;
    }

    #content /deep/ .el-table::-webkit-scrollbar {
        display: none;
    }

    .addAlaForm /deep/ .el-select {
        width: 100% !important;
    }

    .dialog-footer {
        height: 5vh;
        text-align: right;
    }

    .dialog-footer /deep/ .el-button, .el-button--info {
        margin-left: 10px !important;

    }

    .dialog-footer /deep/ .el-button--info {
        background-color: transparent !important;
        color: #000;
    }

    .title /deep/ .el-input__inner {
        height: 33px !important;
    }

    .drawerBox /deep/ .msg {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 1vh 1vw;
    }
    .isUpload /deep/.avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        width: 16vh;
        height: 16vh;
    }
    
    .isUpload /deep/.avatar-uploader .el-upload:hover {
        border-color: #409eff;
    }
    
    .isUpload /deep/.avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 16vh;
        height: 16vh;
        line-height: 147px;
        text-align: center;
    }
    .container .businessImg {
        width: 16vh;
        height: 16vh;
    }

</style>


